import {useEffect, useRef} from 'react'
import {Routes as ReactRoutes, Route, useLocation, useNavigationType, useParams} from 'react-router-dom'
import {Search, SearchResults, JobListing, HowItWorks, ContactUs, Accessibility, Errors} from './pages'

const Routes = () => {
  const location = useLocation()
  const {pathname} = location
  const navigationType = useNavigationType()

  useEffect(() => {
    if (navigationType !== 'POP') {
      window.scrollTo({top: 0, left: 0, behavior: 'instant'})
    }
  }, [pathname, navigationType])

  // by storing the location in a ref we can access it from an effect without having to make it a dependency (effects
  // run every time a dependency changes)
  const locationRef = useRef(location)
  useEffect(() => { locationRef.current = location }, [location])

  const mountedRef = useRef(false)

  // Focus does not work in development when StrictMode is enabled (This works correctly in production)
  // To simulate production, you can remove the StrictMode component from `app/javascript/react/index.js`
  useEffect(() => {
    const {state} = locationRef.current
    // if focus is set in location state focus that element and remove it from the location state
    let focus = state?.focus && document.querySelector(state.focus)
    if (focus) {
      window.history.replaceState({...window.history.state, focus: undefined}, '')
    }

    // if a custom focus has not been specified, focus the first h1
    if (!focus && mountedRef.current) {
      focus = document.querySelector('h1')
      if (focus) {
        focus.tabIndex = -1
      }
    }
    mountedRef.current = true

    focus?.focus({preventScroll: true})
  }, [pathname])

  return (
    <ReactRoutes>
      <Route path="/" element={<Search />} />
      <Route path="/jobs" element={<SearchResults />} />
      <Route path="/locations/:regionPath/jobs" element={<SearchResults />} />
      <Route path="/locations/:regionPath/:areaPath/jobs" element={<SearchResults />} />
      <Route path="/categories/:parentCategoryPath/jobs" element={<SearchResults />} />
      <Route path="/categories/:parentCategoryPath/:subCategoryPath/jobs" element={<SearchResults />} />
      <Route path="/jobs/:id" element={<JobListing />} />
      <Route path="/help" element={<HowItWorks />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/accessibility" element={<Accessibility />} />
      <Route path="/error/:type" element={<ErrorPreview />} />
      <Route path="*" element={<Errors.NotFound />} />
    </ReactRoutes>
  )
}

export default Routes

const ErrorPreview = () => {
  const params = useParams()
  const Component = Errors[params.type] || Errors.NotFound
  return <Component />
}
